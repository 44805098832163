import React from "react";
import { Link } from "gatsby";

const Breadcrumbs = ({ links }) => {
  return (
    <div className="hidden md:block bg-gray-100">
      <div className="container md:pl-12 py-8 flex space-x-2 text-blue-700 text-sm">
        {links.map(([href, text], i) => (
          <React.Fragment key={i}>
            <Link to={href}>
              <span
                className={`${i === links.length - 1 ? "text-pink-900" : ""}`}
              >
                {text}
              </span>
            </Link>
            {i !== links.length - 1 && <span>{" > "}</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
