import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, graphql, useStaticQuery } from "gatsby";
import { convertToSlug } from "../../utils/converters";
import { Bag, Ham, User } from "../shop-icons";
import { isBrowser } from "../../utils/isBrowser";

const Header = () => {
  const data = useStaticQuery(graphql`
    query headerData {
      allCategory(filter: { type: { eq: "Main" } }) {
        edges {
          node {
            _id
            name
          }
        }
      }
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  const { state } = useAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  if (!isBrowser) return null;

  const categories = data.allCategory.edges;
  const logo = data.logo.publicURL;
  const emptyBag = state.bag.items.length === 0;
  const className = state.bag.items.length > 100 ? "w-8" : "w-5";

  let BagCounterContent = emptyBag ? null : (
    <span
      style={{ marginRight: "-0.625rem" }}
      className={`absolute ${className} top-0 right-0 bg-fuschia-700 text-white leading-none -mt-0.5 rounded-full h-5 flex items-center justify-center font-extrabold text-xs`}
    >
      {/** state.bag.items.reduce((sum, item) => sum + item.quantity, 0) */}
      {state.bag.items.length < 100 ? state.bag.items.length : "99+"}
    </span>
  );

  /** 
  let displayName = state.email.substring(0, 5);
  let showDot = state.email.length > 5;
  */

  return (
    <header className="sticky top-0 bg-white z-20 shadow-sm">
      <div className="bg-green-500 text-white hidden md:block">
        <div className="container flex md:space-x-6 lg:space-x-12 py-3 text-sm">
          {categories.map(({ node: category }) => (
            <Link
              to={`/store/category/${convertToSlug(category.name)}`}
              key={category._id}
            >
              {category.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="container flex items-center text-blue-900">
        <Link to="/platform">
          <img src={logo} width={93} height={96} alt="Meia Pata Logo" />
        </Link>
        <nav className="ml-8 hidden md:flex items-center space-x-10 py-10 font-display flex-grow flex-shrink-0">
          <Link to="/platform">Home</Link>
          <Link to="/store">Products</Link>
          <Link to="/contact">Contact us</Link>
          <Link to="/about">About Meia Pata</Link>
        </nav>
        <div className="ml-auto md:ml-2 flex items-end space-x-8">
          <div className="relative">
            <Link to="/platform/account">
              <div className="flex flex-col justify-center py-2 text-center text-sm">
                <span className="mx-auto relative">
                  <User className="w-6 h-6 md:w-8 md:h-8 fill-current mb-1 text-blue-700" />
                </span>
                <span className="hidden md:inline-block font-display">
                  {/**showDot ? `${displayName}...` : state.email*/ "Account"}
                </span>
              </div>
            </Link>
          </div>
          <div className="relative">
            <Link to="/platform/bag">
              <div className="flex flex-col justify-center py-2 text-center text-sm">
                <span className="mx-auto relative">
                  <Bag className="w-6 h-6 md:w-8 md:h-8 fill-current mb-1 text-blue-700" />
                  {BagCounterContent}
                </span>
                <span className="hidden md:inline-block font-display">
                  Shopping Bag
                </span>
              </div>
            </Link>
          </div>
          <button
            className="block md:hidden py-2 text-center text-sm font-display"
            onClick={() => {
              setMobileNavOpen((prevState) => !prevState);
            }}
          >
            <Ham className="h-4 md:w-8 md:h-8 fill-current mx-auto mb-1 text-blue-700" />
          </button>
        </div>
      </div>
      {isMobileNavOpen && (
        <div className="absolute left-0 right-0 z-10">
          <div className="bg-gray-100 relative">
            <button
              className="absolute right-0 p-6 m-2 text-pink-900 font-bold"
              onClick={() => {
                setMobileNavOpen(false);
              }}
            >
              x
            </button>
            <nav className="flex flex-col py-16 px-8 space-y-6 font-display text-blue-900 text-lg">
              <Link to="/platform">Home{" > "}</Link>
              <Link to="/store">Products{" > "}</Link>
              <Link to="/contact">Contact us</Link>
              <Link to="/about">About Meia Pata</Link>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
