import React from "react";
import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";

const Privacy = () => {
  let links = [
    ["/platform", "Home"],
    ["/privacy", "Privacy Policies"],
  ];

  return (
    <ShopLayout>
      <div className="border-b-2 border-dashed border-gray-300">
        <Breadcrumbs links={links} />
        <div className="container lg:pl-12 my-8">
          <div>
            <h1 className="font-display text-blue-900 text-4xl mb-6">
              Privacy Policies
            </h1>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              PRIVACY POLICIES OF MEIA PATA
              <br />
              MEIA PATA, LDA. is committed to the protection of the personal
              data of its customers and more generally of internet users
              browsing the meiapata.com website. This charter explains how your
              data is used as well as your rights relating to your personal
              data. Users who register on the meiapata.com website and its
              sub-domains (hereinafter referred to as the{" "}
              <a
                href="https://www.b2b.meiapata.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textTransform: "none" }}
              >
                https://www.b2b.meiapata.com/
              </a>
              ) may be asked to provide personal data.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              1. Data Controller
              <br /> The data controller is MEIA PATA, LDA., a simplified
              joint-stock company with a capital of 200,00€ under the number 513
              830 898, whose registered office is located at Rua José Cavadas, 8
              4780-486, Santo Tirso hereafter referred to as " MEIA PATA ".
            </p>

            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              2. Collected Data <br />
              MEIA PATA collects the following data on the Website: names,
              postal addresses, e-mail addresses, passwords, landline and/or
              mobile phone numbers, IP addresses, connection data and navigation
              data, order history, data relating to customer claims. Data that
              must be collected for the execution of the service is marked with
              an asterisk.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              3. Legal Basis for Data Collection <br />
              Collected data is the data necessary for the performance of the
              MEIA PATA services and is collected for this purpose. In this
              respect, the legal basis for the collection of personal data via
              the Website is the performance of a contract, whether the MEIA
              PATA services subscribed to are free of charge or subject to
              payment. In the absence of communication of accurate and
              up-to-date personal data necessary for the identification of the
              user and the execution of the services, the services cannot be
              performed by MEIA PATA.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              Data may also be collected in the event of a user’s request for
              information (i.e. for a specific purpose), addressed to MEIA PATA
              by e-mail or via the contact form provided for this purpose. The
              user may then withdraw his or her consent at any time, without
              this affecting the lawfulness of the data processing already
              carried out by MEIA PATA.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              4. Purpose of the Treatment <br />
              User data is used by MEIA PATA solely for the purposes of
              performing the services offered on the Website and for the
              purposes of prospecting and information provided to registered
              persons, in particular by e-mail once they have consented to
              receive such information or if the customers of MEIA PATA have not
              objected to it. Users who are recipients of MEIA PATA newsletters
              and who no longer wish to receive them can unsubscribe at any time
              via the link provided for this purpose in each newsletter. The
              data collected may also be stored for evidentiary purposes and
              exclusively for the required legal period in an archive database.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              5. Right of access, to object, to restriction and to rectification
              of data <br />
              Each user of the Website has a right of access, to rectification,
              to restriction, to erasure and to object to the processing of
              their data and their use for marketing purposes. To that end, the
              user can write to the address of the MEIA PATA company specifying
              in the subject of the letter or e-mail that it is a request for
              information or a claim relating to his or her personal data, and
              will provide proof of receipt by MEIA PATA of his or her request.
              The user must also provide a proof of identity, i.e. at least a
              copy of his or her identity card and proof of residence. Failing
              this, MEIA PATA reserves the right not to proceed with the
              request. The requirement of proof of identity is necessary in
              order to preserve the confidentiality of the data and to direct
              the said data solely to the persons concerned. Mail can be sent to
              the following address: hello@meiapata.pt MEIA PATA, LDA. Rua José
              Cavadas, 8 4780-486, Santo Tirso The User may also write to the
              following e-mail address in order to assert the above-mentioned
              rights: hello@meiapata.pt.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              6. Claims <br />
              The user can submit any claims about the processing of his or her
              personal data to the following address : hello@meiapata.pt MEIA
              PATA will make its best efforts to respond without delay.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              7. Right to Data Portability <br />
              Each user whose data has been collected by MEIA PATA has a right
              to the portability of their data, which must be returned to them
              in a structured, commonly used, machine-readable format.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              8. Right to Provide Advance Directives
              <br />
              The User has the right to issue advance directives regarding the
              treatment of personal data after his or her death. These advance
              directives can be sent to MEIA PATA via the following contact
              e-mail address : hello@meiapata.pt
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              9. Data Security
              <br />
              MEIA PATA is committed to protecting the data of its customers and
              prospects, and makes its best efforts to protect their personal
              data. The Website operates using an HTTPS protocol for data
              collection.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              In addition, the Website users’ connection data and passwords are
              encrypted and salted in order to limit as much as possible the
              risks of fraudulent use.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              10. Data Recipients
              <br />
              The personal data collected is intended for MEIA PATA and its
              subcontractors, and cannot be commercialised in any way.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              11. Duration of Data Storage
              <br />
              The data collected will be kept for the duration of the MEIA PATA
              services for the purposes of their execution and up to three
              months after the deletion of a user account, and for a maximum of
              three years from the last commercial relationship with the
              customer for commercial prospecting purposes.
            </p>
            <p className="text-blue-700 mb-4 lg:max-w-screen-lg">
              12. Password Policy
              <br />
              In order to protect users’ data as well as possible, the password
              they will create for the opening of their user account will be at
              least 6 characters long (one uppercase letter, number and a
              special character ({`!"#$%&'()*+,-./:;<=>?@[\]^_\`{|}~`}).
            </p>
          </div>
        </div>
      </div>
    </ShopLayout>
  );
};

export default Privacy;
