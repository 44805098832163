import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Facebook, Instagram, WhatsApp } from "../shop-icons";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerData {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  const logo = data.logo.publicURL;

  return (
    <footer className="mt-auto">
      <div
        className="container md:pl-12 mx-auto grid grid-cols-1 sm:grid-cols-2 
            lg:grid-cols-4 my-16 md:my-20 gap-8 text-gray-700"
      >
        <div className="w-40 flex flex-col order-first">
          <div className="mb-4">
            <img src={logo} width={164} height={130} alt="Meia Pata Logo" />
          </div>
          <div className="flex justify-between text-white">
            <a
              href="https://www.facebook.com/meiapata"
              className="p-2 bg-blue-900 rounded-full"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook className="w-5 h-5 fill-current" />
            </a>
            <a
              href="https://www.instagram.com/meiapata/"
              className="p-2 bg-blue-900 rounded-full"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram className="w-5 h-5 fill-current" />
            </a>
            <a
              href="https://wa.me/+351913343808"
              className="p-2 bg-blue-900 rounded-full"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsApp className="w-5 h-5 fill-current" />
            </a>
          </div>
        </div>
        <div className="flex flex-col space-y-2 sm:order-3 lg:order-2">
          <div className="text-2xl text-blue-900 font-display lg:mb-5">
            Meia Pata
          </div>
          <Link to="/about">About Meia Pata</Link>
          <Link to="/contact">Franchising</Link>
          <Link to="/contact">Want meia pata in your store?</Link>
        </div>
        <div className="flex flex-col space-y-2 sm:order-2 lg:order-3">
          <div className="text-2xl text-blue-900 font-display lg:mb-5">
            Shop Online
          </div>
          <Link to="/platform/account">Track Your Order</Link>
          <Link to="/contact">Contact Us</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <div className="flex flex-col space-y-2 order-last">
          <div className="text-2xl text-blue-900 font-display lg:mb-5">
            Client Service
          </div>
          <a
            href="https://www.livroreclamacoes.pt/inicio"
            target="_blank"
            rel="noreferrer"
          >
            Complaints Book
          </a>
          <Link to="/privacy">Privacy Policies</Link>
          <Link to="/size-guide">Size Guide</Link>
        </div>
      </div>
      <div className="bg-green-500 ">
        <div className="container flex justify-end items-center py-1" />
      </div>
    </footer>
  );
};

export default Footer;
