import React from "react";
import PropTypes from "prop-types";
import Header from "../shop-header";
import Footer from "../shop-footer";

const ShopLayout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
};

ShopLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShopLayout;
